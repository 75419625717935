import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import CurrencySelector from './CurrencySelector';
import CoinSelector from './CoinSelector';
import '../styles/Form.css';
import authFetch from '../utils/authFetch';
import UseBuySelectorStore from '../store/BuySelector';
import { Link } from 'react-router-dom';

const Form = () => {
  const { globalCurrency, globalCoin, globalNetwork, setGlobalCurrency, setGlobalCoin, setGlobalNetwork, globalYouSend, globalYouGet, setGlobalYouSend, setGlobalYouGet, globalQuoteData, setGlobalQuoteData, globalPaymentMethod, setGlobalPaymentMethod } = UseBuySelectorStore();

  const [isExpanded, setIsExpanded] = useState(false);
  const [focusedInput, setFocusedInput] = useState('pay');
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [isCoinSelectorOpen, setIsCoinSelectorOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [coins, setCoins] = useState([]);
  const [filteredCurrency, setFilteredCurrency] = useState([]);
  const [filteredCoin, setFilteredCoin] = useState([]);
  const payInputRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const debounceTimeout = 1000;
  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handle selecting an option
  const handleSelectOption = (method) => {
    setGlobalPaymentMethod(method);
    setIsDropdownOpen(false);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const preventNegative = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
  };

  const handleButtonClick = (e, amount) => {
    e.preventDefault();
    setGlobalYouSend(amount);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setGlobalYouSend(value);
    }
  };

  const handleYouGetInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setGlobalYouGet(value);
    }
  };

  const getAllCurrencies = async () => {
    try {
      const response = await authFetch('/api/v1/user/getAllCurrencies', {
        method: 'GET',
      });
      if (!response?.code === 200) return;
      const currencyData = response?.data;
      setCurrencies(currencyData);
      setGlobalCurrency(currencyData[0]);
    } catch (error) {
      console.error('Error fetching getAllCurrencies:', error);
    }
  };

  const getAllCoins = async () => {
    try {
      const response = await authFetch('/api/v1/user/getAllCoins', {
        method: 'GET',
      });
      if (!response?.code === 200) return;
      const coinData = response?.data;
      setCoins(coinData);
      setGlobalCoin(coinData[0]);
      if (!globalNetwork) getAllNetworks(coinData[0]);
    } catch (error) {
      console.error('Error fetching getAllCoins:', error);
    }
  };

  const getAllNetworks = async (coinData) => {
    try {
      const response = await authFetch(`/api/v1/user/getAllNetworks?id=${coinData?.coinid}`, {
        method: 'GET',
      });
      if (!response?.code === 200) return;
      const networkData = response?.data;
      const defaultNetwork = networkData.find(n => n.chainSymbol === "trc20")
      setGlobalNetwork(defaultNetwork);
    } catch (error) {
      console.error('Error fetching getAllNetworks:', error);
    }
  };

  const getQuote = async () => {
    try {
      let body = {
        fromCurrency: globalCurrency.fiatSymbol,
        toCurrency: globalCoin.coin.toUpperCase(),
        fromAmount: globalYouSend,
        chain: globalNetwork.chainSymbol,
        paymentMethodType: globalPaymentMethod,
      };
      const response = await authFetch(`/api/v1/user/get-quotes`, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response?.code === 200) return;
      const quoteData = response?.data;
      setGlobalQuoteData(quoteData);
      setGlobalYouGet(quoteData?.toAmount);
    } catch (error) {
      console.error('Error fetching getQuote:', error);
    }
  };

  useEffect(() => {
    payInputRef.current.focus();
    const fetchInitialData = async () => {
      await getAllCurrencies();
      await getAllCoins();
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (globalYouSend !== '' && globalYouSend >= Number(globalNetwork?.minBuyInRupee)) {
        getQuote();
      } else {
        setGlobalQuoteData(null);
        setGlobalYouGet('');
      }
    }, debounceTimeout);
    // Cleanup function to clear timeout if effect dependencies change
    return () => clearTimeout(debounceTimer);
  }, [globalYouSend, globalNetwork, globalCurrency, globalPaymentMethod]);

  useEffect(() => {
    let filteredFiat = currencies.filter(currency =>
      currency?.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
      currency?.fiatSymbol.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCurrency(filteredFiat);

    let filteredCrypto = coins.filter(coin =>
      coin.coinName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      coin.coin.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCoin(filteredCrypto);
  }, [searchTerm, currencies, coins]);

  return (
    <div className="flex flex-col h-[570px]">
      <form className="space-y-3 text-sm flex-grow overflow-auto mt-4 px-4">
        {/* Pay Section */}
        <div className="space-y-3">
          <label className="block text-black font-semibold">Send</label>
          <div
            className={`flex items-center border-2 ${focusedInput === 'pay'
              ? globalYouSend && globalYouSend < Number(globalNetwork?.minBuyInRupee)
                ? 'border-red-500'
                : 'border-green-500'
              : 'border-gray-300'
              } rounded-lg p-0.5 bg-white`}
          >
            <input
              ref={payInputRef}
              type="number"
              value={globalYouSend}
              placeholder={
                globalCurrency?.currencySymbol && globalNetwork?.minBuyInRupee
                  ? `min ${globalCurrency.currencySymbol} ${Number(globalNetwork.minBuyInRupee)}`
                  : ''
              }
              className="bg-white text-black text-lg font-semibold flex-1 outline-none p-3 appearance-none min-w-0"
              onFocus={() => setFocusedInput('pay')}
              onBlur={() => setFocusedInput('')}
              onInput={preventNegative}
              onChange={handleInputChange}
            />
            <div onClick={() => setIsSelectorOpen(true)} className="flex items-center bg-gray-100 px-2 py-4 rounded-r-lg flex-shrink-0 max-w-[35%]">
              <img src={globalCurrency?.flagUrl} alt="Currency Flag" className="h-5 w-5 rounded-full object-cover" />
              <span className="ml-2 text-black text-xs font-semibold uppercase truncate">{globalCurrency?.fiatSymbol}</span>
              <FaChevronDown className="ml-4 text-gray-500 flex-shrink-0" />
            </div>
          </div>
          {globalYouSend && globalYouSend < Number(globalNetwork?.minBuyInRupee) && (
            <p className="text-red-500 text-sm font-semibold">Min amount is {globalCurrency?.currencySymbol} {Number(globalNetwork?.minBuyInRupee)}</p>
          )}
          {globalCurrency?.fiatSymbol === 'INR' && (
            <div className="flex space-x-2">
              <button
                className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                onClick={(e) => handleButtonClick(e, 1000)}
              >
                ₹1,000
              </button>
              <button
                className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                onClick={(e) => handleButtonClick(e, 5000)}
              >
                ₹5,000
              </button>
              <button
                className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                onClick={(e) => handleButtonClick(e, 10000)}
              >
                ₹10,000
              </button>
            </div>
          )}
        </div>

        {/* You Get Section */}
        <div className="space-y-2">
          <label className="block text-black font-semibold">Receive</label>
          <div
            className={`flex items-center border-2 ${focusedInput === 'get' ? 'border-green-500' : 'border-gray-300'} rounded-lg p-0.5 bg-white`}
          >
            <input
              type="number"
              value={globalYouGet}
              placeholder=""
              className="bg-white text-black text-lg font-semibold flex-1 outline-none p-3 appearance-none min-w-0"
              onFocus={() => setFocusedInput('get')}
              onBlur={() => setFocusedInput('')}
              onInput={preventNegative}
              onChange={handleYouGetInputChange}
            />
            <div
              onClick={() => setIsCoinSelectorOpen(true)}
              className="flex items-center bg-gray-100 px-3 py-3 rounded-r-lg flex-shrink-0 min-w-0 max-w-[35%]"
            >
              <img
                src={globalCoin?.coinIcon}
                alt="Coin Icon"
                className="h-5 w-5 rounded-full object-cover flex-shrink-0"
              />
              <div className="flex flex-col items-start ml-1 min-w-0">
                <div className="flex flex-row items-center min-w-0">
                  <span className="text-black text-xs font-semibold uppercase truncate leading-none">{globalCoin?.coin}</span>
                  <FaChevronDown className="ml-1 text-gray-500 flex-shrink-0" />
                </div>
                <span className="text-gray-500 uppercase text-[0.6rem] leading-none mt-1 truncate">{globalNetwork?.chainSymbol?.toUpperCase()}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Method Section */}
        <div className="space-y-2 relative">
          <label className="block text-black font-semibold">Payment Method</label>
          <div
            className="flex items-center border-2 border-gray-300 rounded-lg p-0 bg-white cursor-pointer"
            onClick={toggleDropdown}
          >
            <div className="flex-1 text-black text-lg font-semibold p-3">
              {globalPaymentMethod}
            </div>
            <div className="flex items-center bg-gray-100 px-3 py-4 rounded-r-lg w-auto min-w-[100px]">
              <span className="ml-2 text-black font-semibold text-xs uppercase">
                {globalPaymentMethod}
              </span>
              {isDropdownOpen ? (
                <FaChevronUp className="ml-2 text-gray-500" />
              ) : (
                <FaChevronDown className="ml-2 text-gray-500" />
              )}
            </div>
          </div>

          {isDropdownOpen && (
            <ul className="absolute w-full bg-white border border-gray-300 rounded-lg mt-1 z-10">
              <li
                onClick={() => handleSelectOption("IMPS")}
                className="p-3 hover:bg-gray-100 cursor-pointer"
              >
                IMPS
              </li>
              {/* <li
                onClick={() => handleSelectOption("UPI")}
                className="p-3 hover:bg-gray-100 cursor-pointer"
              >
                UPI
              </li> */}
            </ul>
          )}
        </div>

        {/* Conversion Rate and Fee */}
        <div className="text-xs text-gray-500 flex justify-between -mt-1">
          <span>1 {globalCoin?.coin?.toUpperCase()} ≈ {globalQuoteData && `₹${globalQuoteData?.rate}`}</span>
          <span>FEE: {globalNetwork && Number(globalNetwork?.fee).toFixed(1)} {globalCoin?.coin?.toUpperCase()}</span>
        </div>

        {/* You Pay Section */}
        <div className="border border-gray-300 rounded-lg bg-gray-100">
          <div className="p-5 flex justify-between items-center cursor-pointer" onClick={toggleExpand}>
            <div className="flex-1 overflow-hidden">
              <span className="text-blue-600 font-bold truncate block max-w-full">Send ₹ {globalYouSend !== '' ? globalYouSend : 0}</span>
            </div>
            {isExpanded ? <FaChevronUp className="text-grey-700" /> : <FaChevronDown className="text-gray-700" />}
          </div>

          <CSSTransition in={isExpanded} timeout={300} classNames="expand" unmountOnExit>
            <div className="px-4 pb-4 space-y-2">
              <div className="flex justify-between pb-2">
                <span>Platform fee</span>
                <span>{globalCurrency?.currencySymbol} {globalQuoteData ? globalQuoteData?.fees[0]?.onrampFee : 0}</span>
              </div>
              <div className="flex justify-between pb-2">
                <span className="flex items-center">Blockchain Fee <span className="ml-1 text-gray-400">({globalNetwork && Number(globalNetwork?.fee).toFixed(1)} {globalCoin?.coin?.toUpperCase()})</span></span>
                <span>{globalCurrency?.currencySymbol} {globalQuoteData ? globalQuoteData?.fees[0]?.gasFee : 0}</span>
              </div>
              <div className="flex justify-between pb-2">
                <span>Total fee</span>
                <span>{globalCurrency?.currencySymbol} {globalQuoteData ? (parseFloat(globalQuoteData?.fees[0]?.gasFee) + parseFloat(globalQuoteData?.fees[0]?.onrampFee)).toFixed(2) : 0}</span>
              </div>
            </div>
          </CSSTransition>
        </div>
      </form>


      {/* Fixed Proceed Button */}
      <div className="p-4 bg-white">
        <Link
          to={globalYouSend < Number(globalNetwork?.minBuyInRupee) || !globalQuoteData || globalYouGet === "" ? "#" : '/wallet-address'}
          className={`w-full rounded-lg py-4 font-bold text-lg flex items-center justify-center space-x-2 h-14
            ${globalYouSend < Number(globalNetwork?.minBuyInRupee) || !globalQuoteData || globalYouGet === ""
              ? 'bg-green-200 text-white'
              : 'bg-green-500 text-white hover:bg-green-600'}`
          }
        >
          <span>Proceed</span>
        </Link>
      </div>

      {/* Currency Selector */}
      <CurrencySelector
        isOpen={isSelectorOpen}
        onClose={() => { setIsSelectorOpen(false); setSearchTerm('') }}
        currencies={filteredCurrency}
        onSelect={() => setIsSelectorOpen(false)}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />

      {/* Coin Selector */}
      <CoinSelector
        isOpen={isCoinSelectorOpen}
        onClose={() => { setIsCoinSelectorOpen(false); setSearchTerm('') }}
        coins={filteredCoin}
        onSelect={() => setIsCoinSelectorOpen(false)}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
    </div>
  );
};

export default Form;
