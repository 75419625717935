import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FaTimes, FaPhone, FaGlobe } from 'react-icons/fa';
import { showToast } from '../utils/toast';// Assuming you have the Toast component
import authFetch from '../utils/authFetch';
import { useNavigate } from 'react-router-dom';
const AddPhone = () => {
    const navigate = useNavigate()
    const [isCountrySelectorOpen, setIsCountrySelectorOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({
        name: 'India',
        dialCode: '+91',
        code: 'IN',
        flag: 'path-to-india-flag',
    }); // Default country selection
    const [phone, setPhone] = useState('');
    const [agreed, setAgreed] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const countries = [
        {
            name: 'India',
            dialCode: '+91',
            code: 'IN',
            flag: 'path-to-india-flag',
        },
        {
            name: 'United States',
            dialCode: '+1',
            code: 'US',
            flag: 'path-to-usa-flag',
        },
        {
            name: 'Canada',
            dialCode: '+1',
            code: 'CA',
            flag: 'path-to-canada-flag',
        },
        {
            name: 'United Kingdom',
            dialCode: '+44',
            code: 'GB',
            flag: 'path-to-uk-flag',
        },
        {
            name: 'Australia',
            dialCode: '+61',
            code: 'AU',
            flag: 'path-to-australia-flag',
        },
        {
            name: 'Germany',
            dialCode: '+49',
            code: 'DE',
            flag: 'path-to-germany-flag',
        },
        {
            name: 'France',
            dialCode: '+33',
            code: 'FR',
            flag: 'path-to-france-flag',
        },
        {
            name: 'Japan',
            dialCode: '+81',
            code: 'JP',
            flag: 'path-to-japan-flag',
        },
        {
            name: 'China',
            dialCode: '+86',
            code: 'CN',
            flag: 'path-to-china-flag',
        },
        {
            name: 'South Africa',
            dialCode: '+27',
            code: 'ZA',
            flag: 'path-to-south-africa-flag',
        },
        {
            name: 'Brazil',
            dialCode: '+55',
            code: 'BR',
            flag: 'path-to-brazil-flag',
        },
        {
            name: 'Russia',
            dialCode: '+7',
            code: 'RU',
            flag: 'path-to-russia-flag',
        },
        {
            name: 'Mexico',
            dialCode: '+52',
            code: 'MX',
            flag: 'path-to-mexico-flag',
        },
        {
            name: 'Italy',
            dialCode: '+39',
            code: 'IT',
            flag: 'path-to-italy-flag',
        },
        {
            name: 'Spain',
            dialCode: '+34',
            code: 'ES',
            flag: 'path-to-spain-flag',
        },
        {
            name: 'Saudi Arabia',
            dialCode: '+966',
            code: 'SA',
            flag: 'path-to-saudi-arabia-flag',
        },
        {
            name: 'South Korea',
            dialCode: '+82',
            code: 'KR',
            flag: 'path-to-south-korea-flag',
        },
        {
            name: 'United Arab Emirates',
            dialCode: '+971',
            code: 'AE',
            flag: 'path-to-uae-flag',
        },
        {
            name: 'Turkey',
            dialCode: '+90',
            code: 'TR',
            flag: 'path-to-turkey-flag',
        },
        {
            name: 'Nigeria',
            dialCode: '+234',
            code: 'NG',
            flag: 'path-to-nigeria-flag',
        },
    ];

    const filteredCountries = countries.filter(country =>
        country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        country.dialCode.includes(searchTerm)
    );
    const onSuccess = async (e) => {
        try {
            navigate("/profile")
        } catch (err) {
            console.error("onSuccess", err);
        }
    }

    const handleAddPhone = async (e) => {
        e.preventDefault();

        if (!agreed) {
            showToast('You must agree to the terms and conditions.', 'error');
            return;
        }

        try {
            const body = {
                phone_number: `${selectedCountry.dialCode}-${phone}`,
            };
            const response = await authFetch('/api/v1/user/add/phone', {
                method: 'POST',
                body: body,
            });
            if (response.message) {
                showToast('Phone number added successfully!', 'success');
                onSuccess(); // Handle what happens on success, e.g., redirect or update UI
            } else {
                showToast(response.error || 'Failed to add phone number.', 'error');
            }
        } catch (error) {
            showToast('An error occurred. Please try again.', 'error');
            console.error("handleAddPhone", error);
        }
    };

    return (
        <div className="flex flex-col justify-center h-full w-full p-4">
            <h2 className="text-2xl font-bold text-left text-gray-800 mb-4">Enter Phone Number</h2>
            <form onSubmit={handleAddPhone} className="space-y-6 w-full">
                <div className="w-full relative">
                    <button
                        type="button"
                        onClick={() => setIsCountrySelectorOpen(true)}
                        className="absolute inset-y-0 left-0 flex items-center pl-3 pr-3 bg-gray-100 rounded-l-lg border border-gray-300 h-18"
                    >
                        <img src={selectedCountry.flag} alt={selectedCountry.name} className="h-6 w-6" />
                        <span className="ml-2 text-gray-700 font-semibold">{selectedCountry.dialCode}</span>
                    </button>
                    <input
                        type="number"
                        value={phone}
                        onChange={(e) => {
                            let value = e.target.value;

                            if (value.length > 10) {
                                value = value.slice(0, 10); // Keep only the first 10 digits
                            }

                            setPhone(value);
                        }}
                        placeholder="Enter phone number"
                        className="w-full pl-24 pr-4 py-4 border border-gray-300 rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold h-18"
                        required
                    />
                </div>


                <div className="flex items-center mt-2">
                    <input
                        id="terms"
                        name="terms"
                        type="checkbox"
                        checked={agreed}
                        onChange={() => setAgreed(!agreed)}
                        className="h-5 w-5 text-blue-600 border-gray-300 rounded-sm"
                        style={{ transform: 'scale(1.5)' }}
                    />
                    <label htmlFor="terms" className="ml-5 block text-sm text-gray-700">
                        I agree to the{' '}
                        <a href="#" className="text-blue-500">
                            Terms and Conditions
                        </a>{' '}
                        and{' '}
                        <a href="#" className="text-blue-500">
                            Privacy Policy
                        </a>
                    </label>
                </div>
                <button
                    type="submit"
                    className="w-full bg-green-500 text-white rounded-lg py-4 font-bold text-lg flex items-center justify-center space-x-2 h-14"
                >
                    Continue
                </button>
            </form>


            {/* Country Selector */}
            <CSSTransition
                in={isCountrySelectorOpen}
                timeout={300}
                classNames="slide-full"
                unmountOnExit
            >
                <div className="absolute inset-0 bg-white z-40 p-4 overflow-y-auto">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-medium">Select Country</h2>
                        <FaTimes className="text-xl cursor-pointer" onClick={() => setIsCountrySelectorOpen(false)} />
                    </div>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full mb-4 p-2 border rounded-md"
                    />
                    <ul className="overflow-y-auto">
                        {filteredCountries.map((country) => (
                            <li
                                key={country.code}
                                className="flex justify-between items-center py-2 px-4 hover:bg-gray-100 cursor-pointer"
                                onClick={() => {
                                    setSelectedCountry(country);
                                    setIsCountrySelectorOpen(false);
                                }}
                            >
                                <div className="flex items-center">
                                    <img src={country.flag} alt={country.name} className="h-5 w-5 mr-3" />
                                    <div>
                                        <span className="font-medium">{country.name}</span>
                                        <span className="block text-sm text-gray-500">
                                            {country.dialCode} - {country.code}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </CSSTransition>
        </div>
    );
};

export default AddPhone;
