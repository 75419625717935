import React from 'react';
import { FiMenu, FiArrowLeft } from 'react-icons/fi'; // Importing back icon
import logo from '../assets/logo.jpeg';
import { useNavigate } from 'react-router-dom'; // For navigation

const Header = ({ onMenuClick, fullScreen }) => {
  const navigate = useNavigate(); // To handle back navigation

  return (
    <div className="bg-white p-1 rounded-t-lg flex flex-col items-start justify-between">
      {/* Conditionally render back button if fullScreen is true */}
      {fullScreen && (
        <div
          className="flex items-center cursor-pointer mb-2" // Keep it above the logo
          onClick={() => navigate(-1)} // Go back to previous page
        >
          <FiArrowLeft className="text-lg mr-1" />
          <span className="text-sm">Back</span>
        </div>
      )}

      {/* Logo and Menu Section */}
      <div className="flex items-center justify-between w-full">
        <img src={logo} alt="Logo" className="h-14 w-auto" />
        <FiMenu className="text-2xl cursor-pointer mr-4" onClick={onMenuClick} />
      </div>
    </div>
  );
};

export default Header;
