import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';
import { useNavigate } from 'react-router-dom';
const ChangePassword = () => {
    const navigate = useNavigate()
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // Add a state for form submission
    // Validation states
    const [isOldPasswordValid, setIsOldPasswordValid] = useState(false);
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [errors, setErrors] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    // Toggle password visibility
    const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    // Validate password length
    const validatePassword = (password) => password.length >= 6;

    // Check if the new and confirm passwords match
    const passwordsMatch = () => newPassword === confirmPassword;

    // Validate the old password
    const handleOldPasswordChange = (e) => {
        setOldPassword(e.target.value);
    };

    const handleOldPasswordBlur = () => {
        if (!validatePassword(oldPassword)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                oldPassword: 'At least 6 characters required.',
            }));
            setIsOldPasswordValid(false);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                oldPassword: '',
            }));
            setIsOldPasswordValid(true);
        }
    };

    // Validate the new password
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleNewPasswordBlur = () => {
        if (!validatePassword(newPassword)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPassword: 'At least 6 characters required.',
            }));
            setIsNewPasswordValid(false);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPassword: '',
            }));
            setIsNewPasswordValid(true);
        }
    };

    // Validate the confirm password
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleConfirmPasswordBlur = () => {
        if (!passwordsMatch()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: 'Passwords do not match.',
            }));
            setIsConfirmPasswordValid(false);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: '',
            }));
            setIsConfirmPasswordValid(true);
        }
    };
    const handleSubmit = async () => {
        if (!isFormValid) return;

        setIsSubmitting(true); // Disable button when submitting

        try {
            const body = {
                oldPassword,
                newPassword,
            };

            const response = await authFetch(`/api/v1/user/changePassword`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                showToast(response?.message, "success");
                navigate('/profile'); // After changing password, navigate to login or wherever necessary
            } else {
                showToast(response?.error || "Failed to update password", "error");
            }
        } catch (error) {
            showToast("An error occurred. Please try again.", "error");
        } finally {
            setIsSubmitting(false); // Re-enable button after submission
        }
    };

    // Check if all fields are valid to enable the button
    const isFormValid = isOldPasswordValid && isNewPasswordValid && isConfirmPasswordValid;

    return (
        <div className="flex flex-col h-full min-h-[570px] p-6 bg-white rounded-lg shadow-md">
            {/* Title */}
            <h1 className="text-left text-black text-2xl font-bold">Change your password</h1>
            <p className="text-sm text-left mb-8">You may now set a new password.</p>

            {/* Old Password */}
            <div className="mb-6 relative">
                <label className="block text-sm font-medium text-gray-700 mb-2">Old Password</label>
                <input
                    type={showOldPassword ? 'text' : 'password'}
                    name="oldPassword"
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    onBlur={handleOldPasswordBlur}
                    className={`w-full border p-4 rounded-lg text-sm outline-none ${errors.oldPassword ? 'border-red-500' : 'border-gray-300'
                        } focus:border-green-500 focus:ring-green-200`}
                    placeholder="Enter your old password"
                />
                <span className="absolute top-10 right-4 cursor-pointer" onClick={toggleShowOldPassword}>
                    {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
                {errors.oldPassword && (
                    <p className="text-xs text-red-500 mt-1">{errors.oldPassword}</p>
                )}
            </div>

            {/* New Password */}
            <div className="mb-6 relative">
                <label className="block text-sm font-medium text-gray-700 mb-2">New Password</label>
                <input
                    type={showNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    onBlur={handleNewPasswordBlur}
                    className={`w-full border p-4 rounded-lg text-sm outline-none ${errors.newPassword ? 'border-red-500' : 'border-gray-300'
                        } focus:border-green-500 focus:ring-green-200`}
                    placeholder="Enter your new password"
                />
                <span className="absolute top-10 right-4 cursor-pointer" onClick={toggleShowNewPassword}>
                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
                {errors.newPassword && (
                    <p className="text-xs text-red-500 mt-1">{errors.newPassword}</p>
                )}
            </div>

            {/* Confirm Password */}
            <div className="mb-6 relative">
                <label className="block text-sm font-medium text-gray-700 mb-2">Confirm Password</label>
                <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onBlur={handleConfirmPasswordBlur}
                    className={`w-full border p-4 rounded-lg text-sm outline-none ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'
                        } focus:border-green-500 focus:ring-green-200`}
                    placeholder="Confirm your new password"
                />
                <span className="absolute top-10 right-4 cursor-pointer" onClick={toggleShowConfirmPassword}>
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
                {errors.confirmPassword && (
                    <p className="text-xs text-red-500 mt-1">{errors.confirmPassword}</p>
                )}
            </div>

            {/* Proceed Button */}
            <div className="mt-auto">
                <button
                    onClick={handleSubmit}
                    className={`w-full bg-green-500 text-white py-3 rounded-lg text-md font-bold hover:bg-green-600 transition duration-200 ${(!isFormValid || isSubmitting) ? 'opacity-50' : ''
                        }`}
                    disabled={!isFormValid || isSubmitting}
                >
                    {isSubmitting ? "Updating..." : "Update Password"}
                </button>
            </div>

        </div>
    );
};

export default ChangePassword;
