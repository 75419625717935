import { create } from 'zustand';
const useForgetPasswordStore = create((set) => ({
    globalEmail: '',      // Initial profile state
    globalNewPassword: '',
    setGlobalEmail: (data) => set({ globalEmail: data }), // Function to set Email 
    setGlobalNewPassword: (data) => set({ globalNewPassword: data }), // Function to set New Password 
    clearGlobalEmail: () => set({ globalEmail: '' }),  // Function to clear Email
    clearGlobalNewPassword: () => set({ globalNewPassword: '' }),  // Function to clear New Password
}));

export default useForgetPasswordStore;