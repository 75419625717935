import React, { useState, useRef, useEffect } from 'react';
import {
    FiArrowDown,
    FiArrowUp,
    FiUser,
    FiClock,
    FiGlobe,
    FiGift,
    FiBriefcase,
    FiHelpCircle,
    FiLogIn,
    FiLogOut,
    FiX,
    FiChevronDown,
    FiChevronUp,
    FiShoppingCart,
    FiDollarSign,
    FiRefreshCcw
} from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

const SideMenu = ({ isOpen, toggleMenu, type }) => {
    const location = useLocation(); // Get the current location
    const userToken = localStorage.getItem("userToken");
    const menuRef = useRef(null);
    // State for showing/hiding the History subcategories
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);

    const menuClasses = `transform top-0 right-0 w-72 bg-white absolute h-full overflow-auto ease-in-out transition-all duration-300 z-20 ${isOpen ? 'translate-x-0' : 'translate-x-full'
        }`;

    // Function to check if the current path matches the menu item path
    const isActive = (path) => location.pathname === path;

    // Toggle the menu and close the history submenu when the menu closes
    const handleToggleMenu = () => {
        setIsHistoryOpen(false); // Close the History submenu when toggling the menu
        toggleMenu(); // Call the original toggleMenu function
    };
    // Close the menu if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                // If the menu is open and the click is outside the menu, close it
                if (isOpen) {
                    toggleMenu();
                }
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, toggleMenu]);

    return (
        <div className={menuClasses} ref={menuRef}>
            <div className="flex justify-end items-center p-4">
                <FiX className="text-2xl cursor-pointer" onClick={handleToggleMenu} />
            </div>

            <ul className="p-4 space-y-4 h-full overflow-y-auto pb-8">
                <Link to="/buy">
                    <li
                        className={`flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer 
            ${(isActive('/buy') || (isActive('/') && type === 'onramp')) ? 'text-blue-500 bg-gray-100' : 'text-gray-800'}`}
                        onClick={handleToggleMenu}
                    >
                        <FiArrowDown />
                        <span>Buy</span>
                    </li>
                </Link>

                <Link to="/sell">
                    <li
                        className={`flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer 
            ${(isActive('/sell') || (isActive('/') && type === 'offramp')) ? 'text-blue-500 bg-gray-100' : 'text-gray-800'}`}
                        onClick={handleToggleMenu}
                    >
                        <FiArrowUp />
                        <span>Sell</span>
                    </li>
                </Link>

                {/* <Link to="/swap">
                    <li
                        className={`flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer ${isActive('/swap') ? 'text-blue-500 bg-gray-100' : 'text-gray-800'}`}
                        onClick={handleToggleMenu}
                    >
                        <FiRefreshCcw />
                        <span>Swap</span>
                    </li>
                </Link> */}
                <hr className="my-2" />
                <Link to="/profile">
                    <li
                        className={`flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer ${isActive('/profile') ? 'text-blue-500 bg-gray-100' : 'text-gray-800'}`}
                        onClick={handleToggleMenu}
                    >
                        <FiUser />
                        <span>Profile</span>
                    </li>
                </Link>

                {/* History Section */}
                <li
                    className="flex justify-between font-medium items-center p-3 rounded-lg cursor-pointer"
                    onClick={() => setIsHistoryOpen(!isHistoryOpen)}
                >
                    <div className="flex items-center space-x-3">
                        <FiClock />
                        <span>History</span>
                    </div>
                    {isHistoryOpen ? (
                        <FiChevronUp className="ml-auto text-gray-500 font-medium" />
                    ) : (
                        <FiChevronDown className="ml-auto text-gray-500 font-medium" />
                    )}
                </li>
                {/* Submenu for History */}
                {isHistoryOpen && (
                    <div className="pl-5 space-y-6 bg-gray-200 rounded-md">
                        <Link to="/buy-history">
                            <li
                                className={`flex items-center font-medium space-x-3 p-2 rounded-lg cursor-pointer ${isActive('/history/buy') ? 'text-blue-500 bg-gray-200' : 'text-gray-800 hover:text-gray-500'}`}
                                onClick={handleToggleMenu}
                            >
                                <FiShoppingCart />
                                <span>Buy History</span>
                            </li>
                        </Link>
                        <Link to="/sell-history">
                            <li
                                className={`flex items-center font-medium space-x-3 p-2 rounded-lg cursor-pointer ${isActive('/history/sell') ? 'text-blue-500 bg-gray-200' : 'text-gray-800 hover:text-gray-500'}`}
                                onClick={handleToggleMenu}
                            >
                                <FiDollarSign />
                                <span>Sell History</span>
                            </li>
                        </Link>
                        {/* <Link to="/history/swap">
                            <li
                                className={`flex items-center font-medium space-x-3 p-2 rounded-lg cursor-pointer ${isActive('/history/swap') ? 'text-blue-500 bg-gray-200' : 'text-gray-800 hover:text-gray-500'}`}
                                onClick={handleToggleMenu}
                            >
                                <FiRefreshCcw />
                                <span>Swap History</span>
                            </li>
                        </Link> */}
                    </div>
                )}

                {/* <li
                    className="flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer"
                    onClick={handleToggleMenu}
                >
                    <FiGlobe />
                    <span>Language</span>
                </li> */}
                {/* <li
                    className="flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer"
                    onClick={handleToggleMenu}
                >
                    <FiGift />
                    <span>Refer</span>
                </li> */}
                <Link to="/bank">
                    <li
                        className="flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer"
                        onClick={handleToggleMenu}
                    >
                        <FiBriefcase />
                        <span>Bank Accounts</span>
                    </li>
                </Link>
                <hr className="my-2" />
                {/* <li
                    className="flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer"
                    onClick={handleToggleMenu}
                >
                    <FiHelpCircle />
                    <span>Support</span>
                </li> */}

                {!userToken ? (
                    <Link to="/login">
                        <li
                            className={`flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer ${isActive('/login') ? 'text-blue-500 bg-gray-100' : 'text-gray-800'}`}
                            onClick={handleToggleMenu}
                        >
                            <FiLogIn />
                            <span>Login</span>
                        </li>
                    </Link>
                ) : (
                    <Link to="/logout">
                        <li
                            className={`flex items-center font-medium space-x-3 p-3 rounded-lg cursor-pointer ${isActive('/logout') ? 'text-blue-500 bg-gray-100' : 'text-gray-800'}`}
                            onClick={handleToggleMenu}
                        >
                            <FiLogOut />
                            <span>Logout</span>
                        </li>
                    </Link>
                )}
            </ul>
        </div>
    );
};

export default SideMenu;
