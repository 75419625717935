import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useForgetPasswordStore from '../store/ForgetPasswordStore';
import useSignUpStore from '../store/SignupStore';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';

const OTPVerification = () => {
    const location = useLocation()
    const type = location.state?.type;
    const { globalEmail, globalNewPassword, clearGlobalEmail, clearGlobalNewPassword } = useForgetPasswordStore();
    const { globalEmail: globalSignupEmail, globalPassword, clearGlobalEmail: clearGlobalSignupEmail, clearGlobalPassword } = useSignUpStore()
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [email, setEmail] = useState(''); // Example email, change dynamically if needed
    const [timer, setTimer] = useState(60); // 60 seconds countdown
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false); // Add a state for form submission
    const navigate = useNavigate();

    useEffect(() => {
        setEmail(type === "forgetPassword" ? globalEmail : globalSignupEmail);
    }, [globalEmail, globalSignupEmail]);

    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else {
            setIsResendDisabled(false);
        }
    }, [timer]);

    // Function to handle OTP input changes
    const handleChange = (element, index) => {
        if (!isNaN(element.value) && element.value !== "") {
            let newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Focus to next input
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    // Resend OTP function
    const handleResendOtp = async () => {
        try {
            setIsResendDisabled(true);
            setTimer(60);
            const url = type === "forgetPassword" ? `/api/v1/user/forgetOtp?email=${globalEmail}` : `/api/v1/user/signupOtp?email=${globalSignupEmail}`
            const response = await authFetch(url, {
                method: 'GET',
            });

            if (response?.code === 200) {
                showToast("OTP is sent to your Email", "success");
            } else {
                showToast(response?.error || "Failed to send OTP", "error");
            }
        } catch (error) {
            showToast("An error occurred. Please try again.", "error");
        }
    };

    // Submit OTP for verification
    const handleSubmit = async () => {
        const otpValue = otp.join("");

        if (otpValue.length === 4) {
            setIsSubmitting(true); // Disable the button and show "Verifying..." text

            let body = {
                email: globalEmail,
                otp: otpValue,
                newPassword: globalNewPassword
            };
            const response = await authFetch(`/api/v1/user/changeForgetPassword`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                showToast(response?.message, "success");
                clearGlobalEmail();
                clearGlobalNewPassword();
                navigate("/login");
            } else {
                showToast(response?.error || "Something went wrong", "error");
                setOtp(["", "", "", ""]);
                setIsResendDisabled(false);
            }
        } else {
            showToast("Incomplete OTP", "error");
        }

        setIsSubmitting(false); // Enable the button back after request is done
    };
    // Submit OTP for verification
    const handleSubmitForSignup = async () => {
        const otpValue = otp.join("");

        if (otpValue.length === 4) {
            setIsSubmitting(true); // Disable the button and show "Verifying..." text

            let body = {
                emailId: globalSignupEmail,
                otp: otpValue,
                password: globalPassword
            };
            const response = await authFetch(`/api/v1/user/signup`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                showToast(response?.message, "success");
                clearGlobalSignupEmail();
                clearGlobalPassword();
                navigate("/login");
            } else {
                showToast(response?.error || "Something went wrong", "error");
                setOtp(["", "", "", ""]);
                setIsResendDisabled(false);
            }
        } else {
            showToast("Incomplete OTP", "error");
        }

        setIsSubmitting(false); // Enable the button back after request is done
    };

    // Keydown handler for Backspace and Delete keys
    const handleKeyDown = (e, index) => {
        if ((e.key === "Backspace" || e.key === "Delete") && otp[index] === "") {
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        } else if (e.key === "Backspace" || e.key === "Delete") {
            let newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };

    return (
        <div className="h-[60vh] flex flex-col">
            <div className="flex flex-col items-center flex-grow">
                <div className="bg-white p-8 rounded-lg w-full max-w-sm">
                    <h1 className="text-xl font-bold text-center mb-4">Enter the verification code</h1>
                    <p className="text-center text-sm text-gray-500 mb-8">
                        We've sent a message to <span className="font-bold">{email}</span>
                    </p>

                    <div className="flex justify-between mb-6">
                        {otp.map((data, index) => (
                            <input
                                key={index}
                                type="text"
                                className="w-14 h-14 text-center border border-gray-300 rounded-lg text-lg focus:ring focus:ring-green-200 outline-none"
                                maxLength="1"
                                value={data}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                            />
                        ))}
                    </div>
                    <div className="text-center text-sm text-gray-500 mt-4">
                        {isResendDisabled ? (
                            <p>You can resend the verification code in 00:{timer < 10 ? `0${timer}` : timer}</p>
                        ) : (
                            <button
                                onClick={handleResendOtp}
                                className="text-blue-600 font-semibold hover:underline"
                                disabled={isResendDisabled}
                            >
                                Resend verification code
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {/* Fixed Button at Bottom */}
            <div className="p-4">
                <button
                    onClick={type === "forgetPassword" ? handleSubmit : handleSubmitForSignup}
                    className={`w-full bg-green-600 text-white py-3 rounded-lg text-md font-bold hover:bg-green-700 transition duration-200 ${otp.join("").length !== 4 || isSubmitting ? 'opacity-50' : ''
                        }`}
                    disabled={otp.join("").length !== 4 || isSubmitting}
                >
                    {isSubmitting ? "Verifying..." : "Verify"}
                </button>
            </div>
        </div>
    );
};

export default OTPVerification;
