import { create } from 'zustand';
import authFetch from '../utils/authFetch';
const useProfileStore = create((set) => ({
    globalProfile: null,      // Initial profile state
    setGlobalProfile: (data) => set({ globalProfile: data }), // Function to set profile data
    clearGlobalProfile: () => set({ globalProfile: null }),  // Function to clear profile data (e.g., on logout)
    setProfile: async () => {
        try {
            const response = await authFetch('/api/v1/user/profile', {
                method: 'GET',
            });
            if (response) {
                set({ globalProfile: response.data })
            } else {
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    }
}));

export default useProfileStore;
