import React, { useState, useEffect } from 'react';
import { FiLock, FiEye, FiEyeOff } from 'react-icons/fi';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import useSignUpStore from '../store/SignupStore';
import PuffLoader from "react-spinners/PuffLoader";
const Signup = () => {
    const location = useLocation();
    const { globalEmail, globalPassword, setGlobalEmail, setGlobalPassword, clearGlobalEmail, clearGlobalPassword } = useSignUpStore()
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // Eye icon state for password
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Eye icon state for confirm password
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    // Password validation function
    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordRegex.test(password);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsSubmitting(true);
            let hasErrors = false;
            let newErrors = {
                email: '',
                password: '',
                confirmPassword: '',
            };
            // Email validation
            if (!globalEmail) {
                newErrors.email = 'Email is required.';
                hasErrors = true;
            }
            // Password validation
            if (!validatePassword(globalPassword)) {
                newErrors.password = 'Password must: \n- Be at least 8 characters long \n- Contain at least 1 uppercase letter (A-Z) \n- Contain at least 1 digit (0-9) \n- Contain at least 1 special character (!@#$%^&*)';
                hasErrors = true;
            }
            // Confirm Password validation
            if (globalPassword !== confirmPassword) {
                newErrors.confirmPassword = 'Passwords do not match.';
                hasErrors = true;
            }
            setErrors(newErrors);
            if (hasErrors) {
                setIsSubmitting(false); // Re-enable button if there are errors
                return;
            }
            const response = await authFetch(`/api/v1/user/signupOtp?email=${globalEmail}`, {
                method: 'GET', // Assuming it's a GET request
            });
            if (response?.code === 200) {
                showToast("OTP is sent to your Email", "success");
                navigate('/otp', { state: { type: "signup" } }); // Navigate to OTP page
            } else {
                showToast(response?.error || "Failed to send OTP", "error");
            }
        } catch (error) {
            showToast("An error occurred. Please try again.", "error");
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
    };
    useEffect(() => {
        // return () => {
        // If the location is NOT '/otp', clear the global email and password
        if (location.pathname !== '/otp') {
            clearGlobalEmail();
            clearGlobalPassword();
        }

    }, [location.pathname, clearGlobalEmail, clearGlobalEmail]); // Track location pathname


    return (
        <div className="flex flex-col justify-between h-full w-full">
            {/* PuffLoader with overlay */}
            {isSubmitting && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60 z-50">
                    <PuffLoader color="#10B981" size={100} />
                </div>
            )}
            {/* Signup Form */}
            <div className={`flex-1 overflow-auto ${isSubmitting ? 'filter blur-md' : ''}`}> {/* Blur when submitting */}
                <div className="flex items-center justify-center mb-6">
                    <FiLock className="text-green-500 h-8 w-8" />
                    <h2 className="text-3xl font-bold text-left text-gray-800 ml-2">Signup</h2>
                </div>
                <form onSubmit={handleSubmit} className="space-y-6 w-full">
                    <div className="w-full">
                        <label htmlFor="email" className="block text-sm font-semibold text-gray-700 mb-2">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={globalEmail}
                            onChange={(e) => setGlobalEmail(e.target.value)}
                            className={`w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold`}
                            placeholder="Enter your email"
                            required
                        />
                        {errors.email && <p className="text-xs text-red-500 mt-1">{errors.email}</p>}
                    </div>

                    {/* Password Input */}
                    <div className="w-full relative">
                        <label htmlFor="password" className="block text-sm font-semibold text-gray-700 mb-2">Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={globalPassword}
                            onChange={(e) => setGlobalPassword(e.target.value)}
                            className={`w-full p-3 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold`}
                            placeholder="Create a password"
                            required
                        />
                        {/* Eye icon toggle */}
                        <div
                            className="absolute top-11 right-4 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FiEyeOff /> : <FiEye />}
                        </div>



                        {errors.password && <p className="text-xs text-red-500 mt-1">{errors.password}</p>}
                    </div>

                    {/* Confirm Password Input */}
                    <div className="w-full relative">
                        <label htmlFor="confirmPassword" className="block text-sm font-semibold text-gray-700 mb-2">Confirm Password</label>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={`w-full p-3 border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold`}
                            placeholder="Confirm your password"
                            required
                        />
                        {/* Eye icon toggle */}
                        <div
                            className="absolute top-11 right-4 cursor-pointer"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                        </div>
                        {errors.confirmPassword && <p className="text-xs text-red-500 mt-1">{errors.confirmPassword}</p>}
                    </div>

                    {/* Terms & Conditions */}
                    <div className="flex items-center mt-4">
                        <input
                            id="terms"
                            name="terms"
                            type="checkbox"
                            required
                            className="h-6 w-6 text-blue-600 border-gray-300 rounded ml-2"
                        />
                        <label htmlFor="terms" className="ml-4 text-sm text-gray-700">
                            I agree to the{' '}
                            <a href="#" className="text-blue-500 font-semibold">
                                Terms and Conditions
                            </a>{' '}
                            and{' '}
                            <a href="#" className="text-blue-500 font-semibold">
                                Privacy Policy
                            </a>
                        </label>
                    </div>

                    {/* Signup Button */}
                    <button
                        type="submit"
                        disabled={isSubmitting} // Disable when submitting
                        className={`w-full rounded-lg py-3 font-bold text-lg mt-4 transition duration-200 ${isSubmitting ? 'bg-green-200' : 'bg-green-500 hover:bg-green-600'} text-white`}
                    >
                        {isSubmitting ? 'Submitting...' : 'Signup'}
                    </button>
                </form>

                {/* Already have an account? */}
                <div className="mt-6 text-center w-full">
                    <p className="text-sm text-gray-600">
                        Already have an account?{' '}
                        <Link
                            to={'/login'}
                            className="text-green-500 font-semibold hover:underline"
                        >
                            Login
                        </Link>
                    </p>
                </div>
            </div>

            {/* Security Information */}
            <div className="mt-8 text-center w-full px-4 py-3 bg-gray-100 rounded-lg">
                <div className="flex items-center justify-center">
                    <FiLock className="h-5 w-5 mr-2 text-gray-600" />
                    <div className="text-xs text-gray-600">
                        <p>Securely stored, no spam</p>
                        <p>Your email will not be shared or used for spam emails/messages</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
