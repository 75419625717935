import { useState, useEffect } from "react";
import "./index.css";
import Widget from "./components/Widget";
import bgImage from './assets/background.jpeg';
import PuffLoader from "react-spinners/PuffLoader";
import Toast from './utils/toast';
import { FaArrowLeft } from 'react-icons/fa';

export default function App() {
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState('');
  const [type, setType] = useState('onramp');
  
  useEffect(() => {
    const fetchIpAndCountry = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const typeParam = params.get('type');
        setType(typeParam || 'onramp');

        if (!typeParam) {
          window.history.replaceState(null, '', '/?type=onramp');
        }
      } catch (error) {
        console.error('Error fetching IP or country:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIpAndCountry();
  }, []);

  if (loading) {
    return (
      <div style={styles.loaderContainer}>
        <PuffLoader color="#28d849" size={60} />
      </div>
    );
  }

  return (
    <div className="App">
      <Toast />
      <div className="min-h-screen w-full relative">
        <div
          className="hidden md:flex justify-center items-center min-h-screen bg-cover bg-center w-full relative"
          style={{ backgroundImage: `url(${bgImage})`, flexDirection: 'column' }}
        >
          <div className="relative">
            <div className="absolute -top-9 left-2 cursor-pointer flex items-center space-x-2 p-2 z-10">
              <FaArrowLeft
                className="text-lg"
                onClick={() => {
                  window.history.back();
                }}
              />
            </div>
            
            {/* Widget */}
            <Widget type={type} />
            
            {/* Powered by Text - Positioned just below the Widget */}
            <div style={styles.poweredByText}>
              Powered by GSX Solutions Private Limited
            </div>
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden">

        <div className="flex md:hidden justify-center items-center min-h-screen w-full bg-white">
          <Widget fullScreen={true} type={type} />
        </div>
        <div style={styles.poweredByText}>
            Powered by GSX Solutions Private Limited
          </div>
      </div>
      </div>
    </div>
  );
}

const styles = {
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  poweredByText: {
    marginTop: '10px', // Spacing below the widget
    textAlign: 'center',
    fontSize: '10px', // Small font size
    fontWeight: 'normal',
    color: '#888', // Light gray color
  }
};
