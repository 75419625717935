import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useForgetPasswordStore from '../store/ForgetPasswordStore';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';

const ForgetPassword = () => {
    const { globalEmail, globalNewPassword, setGlobalEmail, setGlobalNewPassword, clearGlobalEmail, clearGlobalNewPassword } = useForgetPasswordStore();
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState({
        email: '',
        newPassword: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Email validation
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Password validation
    const validatePassword = (password) => {
        return password.length >= 8;
    };

    // Handle input changes without validation
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            setGlobalEmail(value);
        } else if (name === 'newPassword') {
            setGlobalNewPassword(value);
        }

        // Check overall form validity
        if (validateEmail(globalEmail) && validatePassword(globalNewPassword)) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    // Handle validation when leaving input fields
    const handleBlur = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            if (!validateEmail(value)) {
                setErrors((prev) => ({
                    ...prev,
                    email: 'Invalid email address.',
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    email: '',
                }));
            }
        } else if (name === 'newPassword') {
            if (!validatePassword(value)) {
                setErrors((prev) => ({
                    ...prev,
                    newPassword: 'Password should be at least 8 characters.',
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    newPassword: '',
                }));
            }
        }

        // Check overall form validity after blur
        if (validateEmail(globalEmail) && validatePassword(globalNewPassword)) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        if (!isFormValid) return;

        setIsSubmitting(true);

        try {
            const response = await authFetch(`/api/v1/user/forgetOtp?email=${globalEmail}`, {
                method: 'GET', // Assuming it's a GET request
            });

            if (response?.code === 200) {
                showToast("OTP is sent to your Email", "success");
                navigate('/otp', { state: { type: "forgetPassword" } }); // Navigate to OTP page
            } else {
                showToast(response?.error || "Failed to send OTP", "error");
            }
        } catch (error) {
            showToast("An error occurred. Please try again.", "error");
        } finally {
            setIsSubmitting(false);
        }
    };

    // Effect to clear global state on component unmount or when navigating to a different route
    useEffect(() => {
        // return () => {
        // If the location is NOT '/otp', clear the global email and password
        if (location.pathname !== '/otp') {
            clearGlobalEmail();
            clearGlobalNewPassword();
        }

    }, [location.pathname, clearGlobalEmail, clearGlobalNewPassword]); // Track location pathname

    return (
        <div className="flex flex-col h-full min-h-[570px]">
            {/* Main Content */}
            <div className="flex-grow bg-white p-8 overflow-auto">
                <h1 className="text-xl font-bold text-gray-900 mb-2 text-center">Reset Password</h1>
                <p className="text-xs text-center text-gray-600 mb-6">
                    OTP will be sent to this email for verification.
                </p>

                {/* Email Input */}
                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={globalEmail}
                        onChange={handleInputChange}
                        onBlur={handleBlur} // Validate on blur
                        className={`w-full border p-4 rounded-lg text-sm outline-none ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:border-green-500 focus:ring-green-200`}
                        placeholder="Enter your email"
                    />
                    {errors.email && (
                        <p className="text-xs text-red-500 mt-1">{errors.email}</p>
                    )}
                </div>

                {/* New Password Input */}
                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">New Password</label>
                    <input
                        type="password"
                        name="newPassword"
                        value={globalNewPassword}
                        onChange={handleInputChange}
                        onBlur={handleBlur} // Validate on blur
                        className={`w-full border p-4 rounded-lg text-sm outline-none ${errors.newPassword ? 'border-red-500' : 'border-gray-300'} focus:border-green-500 focus:ring-green-200`}
                        placeholder="Enter your new password"
                    />
                    {errors.newPassword && (
                        <p className="text-xs text-red-500 mt-1">{errors.newPassword}</p>
                    )}
                </div>
            </div>

            {/* Fixed Proceed Button at Bottom */}
            <div className="p-4 bg-white">
                <button
                    onClick={handleSubmit}
                    className={`w-full py-3 rounded-lg text-md text-white font-bold transition duration-200 
            ${isSubmitting ? 'bg-green-600 opacity-50' : 'bg-green-600 hover:bg-green-700'} 
            ${!isFormValid ? 'opacity-50' : ''}`}
                    disabled={!isFormValid || isSubmitting}
                >
                    {isSubmitting ? "Submitting..." : "Proceed"}
                </button>
            </div>

        </div>
    );
};

export default ForgetPassword;
