import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UseBuySelectorStore from '../store/BuySelector';
const UPICheckout = () => {
    const { globalCurrency } = UseBuySelectorStore()
    const location = useLocation();
    const paymentData = location.state; // This is the data you passed
    const navigate = useNavigate()
    const handleConfirm = () => {
        navigate('/confirm', { state: "buy" })
    };
    return (
        <div className="flex flex-col h-[65vh] justify-between">
            {/* Content */}
            <div className="overflow-y-auto p-4 space-y-5">
                {/* UPI Information Section */}
                <div className="space-y-5">
                    <h2 className="text-md font-bold text-gray-900">UPI Information</h2>

                    <div className="space-y-8">
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">You Send</span>
                            <span className="text-sm font-medium text-gray-800 ">{globalCurrency?.currencySymbol} {paymentData?.amount}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">VPA</span>
                            <span className="text-sm font-medium text-gray-800 ">{paymentData?.upi?.vpa}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">Name</span>
                            <span className="text-sm font-medium text-gray-800">{paymentData?.upi?.name}</span>
                        </div>
                    </div>
                </div>

                {/* UPI Notes Section */}
                <div className="space-y-5">
                    <h2 className="text-md font-bold text-gray-900">UPI Checkout Notes</h2>

                    <ul className="space-y-5 list-disc pl-6 text-sm text-gray-700">
                        {
                            paymentData && paymentData?.upiNotes?.map((item, index) => (
                                <li key={index}>{item?.msg}</li>
                            ))
                        }

                    </ul>
                </div>
            </div>

            {/* Fixed Confirm Button */}
            <div className="p-4">
                <button
                    onClick={handleConfirm}
                    className="w-full bg-green-500 text-white py-3 rounded-lg text-md font-bold hover:bg-green-600 transition duration-200"
                >
                    Confirm
                </button>
            </div>
        </div>
    );
};

export default UPICheckout;
