import React, { useState, useEffect } from 'react';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import authFetch from '../utils/authFetch'; // Assuming authFetch is the utility to fetch data
import UseBuySelectorStore from '../store/BuySelector';
import UseSellSelectorStore from '../store/SellSelector';
const NetworkSelector = ({ isOpen, onClose, onSelect }) => {
    const isSellPage = window.location.pathname.includes('/sell');
    const [networks, setNetworks] = useState([]);
    // Extract values from both stores
    const { setGlobalNetwork: setBuyGlobalNetwork, globalCoin: buyGlobalCoin } = UseBuySelectorStore();
    const { setGlobalNetwork: setSellGlobalNetwork, globalCoin: sellGlobalCoin } = UseSellSelectorStore();
    // Conditionally choose which setGlobalNetwork and globalCoin to use
    const setGlobalNetwork = isSellPage ? setSellGlobalNetwork : setBuyGlobalNetwork;
    const globalCoin = isSellPage ? sellGlobalCoin : buyGlobalCoin;
    useEffect(() => {
        const fetchNetworks = async () => {
            if (!globalCoin) return;
            try {
                const response = await authFetch(`/api/v1/user/getAllNetworks?id=${globalCoin?.coinid}`);
                if (response?.code === 200) {
                    setNetworks(response.data);
                }
            } catch (error) {
                console.error('Error fetching networks:', error);
            }
        };
        fetchNetworks();
    }, [globalCoin]);

    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="slide-full"
            unmountOnExit
        >
            <div className="absolute inset-0 bg-white z-40 p-4 overflow-y-auto">
                {/* Header with Back Button and Logo */}
                <div className="flex items-center justify-between mb-4">
                    <FaChevronLeft className="text-sm cursor-pointer" onClick={onClose} />
                    <div className="flex items-center space-x-0">
                        <img src={globalCoin?.coinIcon} alt={globalCoin?.coinName} className="h-5 w-5 mr-2 rounded-full" />
                        <h4 className="text-lg font-semibold">{globalCoin?.coinName}</h4>
                    </div>
                    {/* This keeps the space even in the middle */}
                    <div style={{ width: '24px' }}></div>
                </div>

                {/* Explanation and "What's this?" Link */}
                <div className="text-gray-600 mb-6 text-left">
                    {/* First Line: "Select blockchain network" with semi-bold and dark text */}
                    <p className="text-md font-semibold text-black">Select blockchain network</p>

                    {/* Second Line: Small text with grey color */}
                    <p className="text-xs text-gray-700">Ensure that you use the same network on the other end.</p>

                    {/* Third Line: "What's this?" in blue, medium font size, and semi-bold, without underline */}
                    <p className="mt-1">
                        <a href="#" className="text-blue-600 text-sm font-semibold">What’s this?</a>
                    </p>
                </div>


                {/* Table Headers */}
                <div className="flex justify-between font-small text-gray-600 text-sm mb-2">
                    <span className="w-1/4">Network</span>
                    {/* Conditionally rendered parts */}
                    {isSellPage ? (
                        <>
                            <span className="w-1/4 text-right"></span> {/* Empty space to match the layout */}
                            <span className="w-1/4 text-right">Status</span>
                        </>
                    ) : (
                        <>
                            <span className="w-1/4 text-right">
                                Network Fee ({globalCoin?.coin?.toUpperCase()})
                            </span>
                            <span className="w-1/4 text-right">Min Buy</span>
                        </>
                    )}
                    <span className="w-[5%]"></span> {/* Reserved small space for the right icon */}
                </div>

                <ul className="space-y-4">
                    {networks.map((network, index) => (
                        <li
                            key={index}
                            className="flex justify-between items-center border-b py-4 hover:bg-gray-100 cursor-pointer"
                            onClick={() => { setGlobalNetwork(network); onSelect() }}
                        >
                            {/* Network Information */}
                            <div className="flex items-center w-1/4">
                                <div className="relative flex items-center bg-gray-100 rounded-full">
                                    {/* Circular icon container */}
                                    <div className="absolute left-[0px] bg-white rounded-full p-1">
                                        <img src={network?.icon} alt={network?.chainName} className="h-5 w-5 rounded-full" />
                                    </div>
                                    {/* Chain name background */}
                                    <span className="font-semibold text-sm text-gray-700 bg-gray-200 rounded-l-full pr-2 pl-9">
                                        {network?.chainSymbol?.toUpperCase()}
                                    </span>
                                </div>
                            </div>


                            {/* Network Fee and Min Buy or Insync */}
                            {isSellPage ? (
                                <div className="w-1/2 text-right text-black font-semibold">
                                    <span className="text-xs">{network?.inSync ? "In Sync" : ''}</span>
                                </div>
                            ) : (
                                <>
                                    {/* Network Fee */}
                                    <div className="w-1/4 text-right text-black font-semibold">
                                        <span className="text-xs">{Number(network?.fee).toFixed(1)}</span> {/* Static value */}
                                    </div>

                                    {/* Min Buy */}
                                    <div className="w-1/4 text-right text-black font-semibold">
                                        <span className="text-xs">{Number(network?.minBuyInRupee).toFixed(0)}</span> {/* Static value */}
                                    </div>
                                </>
                            )}

                            {/* Right Icon */}
                            <div className="w-[5%] text-right text-gray-400">
                                <FaChevronRight />
                            </div>
                        </li>
                    ))}
                </ul>

            </div>
        </CSSTransition>
    );
};

export default NetworkSelector;
