import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import authFetch from '../utils/authFetch';
import UseSellSelectorStore from '../store/SellSelector';
import useProfileStore from '../store/profileStore';
import { showToast } from '../utils/toast';

const SelectBank = () => {
    const navigate = useNavigate();
    const {
        globalSelectedBank,
        setGlobalSelectedBank,
        globalCurrency,
        globalCoin,
        globalNetwork,
        globalYouSell,
        globalYouGet,
        globalQuoteData
    } = UseSellSelectorStore();
    const { globalProfile } = useProfileStore();
    const [bankAccounts, setBankAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Function to fetch bank accounts from the API
    const fetchBankAccounts = async () => {
        setLoading(true); // Start loading
        try {
            const response = await authFetch('/api/v1/offramp/getAllFiatAccount', { method: 'GET' });
            if (response?.code === 200) {
                setBankAccounts(response?.data); // Update state with fetched bank accounts
            }
        } catch (error) {
            console.error('Error fetching bank accounts:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleProceed = async () => {
        try {
            // Check if the user is logged in and KYC status
            if (!globalProfile) {
                navigate("/login");
                return;
            }
            if (!globalProfile?.isPhoneAdded) {
                navigate("/addPhone");
                return;
            }

            // if (!globalProfile?.isKycCompleted) {
            //     navigate("/before-kyc");
            //     return;
            // }

            let body = {
                fromCurrency: globalCoin?.coin.toUpperCase(),
                toCurrency: globalCurrency?.fiatSymbol,
                chain: globalNetwork.chainSymbol,
                fromAmount: globalYouSell,
                toAmount: globalYouGet,
                fiatAccountId: globalSelectedBank?.fiatAccountId, // Updated to use globalSelectedBank
                rate: globalQuoteData?.rate
            };

            const response = await authFetch(`/api/v1/offramp/createOfframpTransaction`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code !== 200) {
                showToast(response?.error, "error");
                return;
            }
            showToast(response?.message, "success");
            const orderCreatedResp = response?.data;
            orderCreatedResp.amount = globalYouSell;
            navigate('/wallet-checkout', { state: orderCreatedResp });
        } catch (error) {
            console.error('Error fetching handleProceed:', error);
        }
    };

    useEffect(() => {
        fetchBankAccounts();
    }, []);

    // Function to handle the selection of a bank
    const handleBankSelect = (account) => {
        setGlobalSelectedBank(account); // Set the selected bank account object in global state
    };

    return (
        <div className="flex flex-col h-[55vh] justify-between">
            <div className="flex-1 bg-white p-6 overflow-auto">
                <h1 className="text-lg font-bold text-gray-800 mb-4">Select Bank Account</h1>

                {loading ? (
                    <div className="flex justify-center items-center mt-10">
                        {/* PuffLoader for loading spinner */}
                        <PuffLoader color="#3498db" size={60} />
                    </div>
                ) : bankAccounts.length === 0 ? (
                    <div className="mt-10 p-4 bg-white shadow-lg rounded-lg text-center">
                        <p className="font-semibold text-gray-900">You've not added any bank accounts yet.</p>
                        <p className="text-gray-500 mt-2 text-sm">
                            Please click on the button below to add a bank account.
                        </p>
                    </div>
                ) : (
                    <div className="space-y-4">
                        {/* Map through the bank accounts and display them */}
                        {bankAccounts.map((account, index) => (
                            <div
                                key={index}
                                className={`p-4 bg-white shadow-lg rounded-lg cursor-pointer flex justify-between items-center ${globalSelectedBank?.fiatAccountId === account?.fiatAccountId ? 'border-2 border-green-600' : 'border border-gray-300'
                                    }`}
                                onClick={() => handleBankSelect(account)} // On click, select the entire bank object
                            >
                                <div>
                                    <p className="font-semibold text-gray-800">{account?.bank_name}</p>
                                    <p className="text-gray-500 text-sm">Account Name: {account?.account_name}</p>
                                    <p className="text-gray-500 text-sm">Account Number: {account?.fiatAccount}</p>
                                    <p className="text-gray-500 text-sm">IFSC: {account?.ifsc}</p>
                                </div>
                                {globalSelectedBank?.fiatAccountId === account?.fiatAccountId && (
                                    <div className="text-green-600 font-semibold">Selected</div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* If no bank accounts, show "Add Bank Accounts" button, otherwise "Proceed" button */}
            <div className="w-full p-4 bg-white">
                {bankAccounts.length === 0 ? (
                    <Link to="/add-bank">
                        <button className="w-full bg-green-600 text-white py-3 rounded-lg text-md font-bold hover:bg-green-700 transition duration-200">
                            Add Bank Accounts
                        </button>
                    </Link>
                ) : (
                    <button
                        onClick={handleProceed}
                        className={`w-full bg-green-600 text-white py-3 rounded-lg text-md font-bold transition duration-200 ${globalSelectedBank === null ? 'opacity-50' : 'hover:bg-green-700'
                            }`}
                        disabled={globalSelectedBank === null}
                    >
                        Proceed
                    </button>
                )}
            </div>
        </div>
    );
};

export default SelectBank;
