import React, { useEffect, useState } from 'react';
import digisvg from "../assets/svgs/digi.Bdzx4YsT.svg"
import signzysvg from "../assets/svgs/signzy.Dr9qxJQ2.svg"
import kycsvg from "../assets/svgs/download.svg"
import authFetch from '../utils/authFetch';
const BeforeKYC = () => {
    const [kycUrl, setKycUrl] = useState()
    const getKycUrl = async () => {
        try {
            const response = await authFetch('/api/v1/user/kyc/url', {
                method: 'GET',
            });

            if (response?.code === 200) {
                setKycUrl(response?.data?.kyc_url);

            }
        } catch (error) {
            console.error('Error fetching getKycUrl:', error);
        }
    };
    useEffect(() => {
        getKycUrl()
    }, [])
    return (
        <div className="flex flex-col justify-between h-[65vh] bg-white rounded-lg shadow-md p-6">
            {/* Main Content */}
            <div className="flex-1 space-y-6">
                {/* Icon */}
                <div className="flex-1 space-y-0">
                    <img src={kycsvg} alt="KYC" className="h-15" />

                    {/* Title */}
                    <h2 className="text-2xl font-semibold text-black">Complete your KYC</h2>

                    {/* Description */}
                    <p className="text-black">
                        We'll need to verify your details to complete your transaction. You just need:
                    </p>
                </div>

                <ul className="space-y-2 list-none"> {/* Removed default list styling */}
                    <li className="flex items-center font-medium text-black">
                        <span className="text-blue-600 mr-3 text-3xl leading-none">•</span> Aadhaar number
                    </li>
                    <li className="flex items-center font-medium text-black">
                        <span className="text-blue-600 mr-3 text-3xl leading-none">•</span> PAN number
                    </li>
                </ul>

                {/* Note */}
                <p className="text-xs text-gray-500">
                    Note: Follow instructions correctly to get KYC verified instantly, or it may be delayed by 24-48 hours.
                </p>

                {/* Powered By Section */}
                <div className="text-left mt-6">
                    <p className="text-sm text-gray-500">Powered by</p>
                    <div className="flex space-x-4 mt-2">
                        <img src={digisvg} alt="DigiLocker" className="h-6" />
                        <img src={signzysvg} alt="Signzy" className="h-6" />
                    </div>
                </div>
            </div>

            {/* Footer with Proceed Button */}
            <div className="mt-6">
                <a
                    href={kycUrl} // External URL
                    target="_blank" // Open in a new tab
                    rel="noopener noreferrer" // For security reasons
                    className="w-full bg-green-600 text-white py-3 rounded-lg text-md font-bold hover:bg-green-700 transition duration-200 text-center block"
                >
                    Proceed
                </a>
            </div>

        </div>
    );
};

export default BeforeKYC;
